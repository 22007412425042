/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guard/AuthGuard';
import GuestGuard from 'src/components/Guard/GuestGuard';
import AdminGuard from './components/Guard/AdminGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password',
    component: lazy(() => import('src/views/auth/RecoverPasswordView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password/reset-form/:email/:token',
    component: lazy(() =>
      import('src/views/auth/RecoverPasswordView/NewPasswordView/')
    )
  },

  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },

      {
        exact: true,
        path: '/app/documents',
        component: lazy(() =>
          import('src/views/reports/DashboardView/documents')
        )
      },

      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },

      {
        exact: true,
        path: '/app/documents/details',
        component: lazy(() =>
          import('src/views/reports/DashboardView/documents/DocumentDetails')
        )
      },

      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/management/companies',
        component: lazy(() => import('src/views/management/Companies'))
      },
      {
        exact: true,
        path: '/app/management/companies/create',
        component: lazy(() => import('src/views/management/Companies/Create'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/administrator',
    layout: DashboardLayout,
    guard: AdminGuard,
    routes: [
      {
        exact: true,
        path: '/administrator',
        component: () => <Redirect to="/administrator/dashboard" />
      },
      {
        exact: true,
        path: '/administrator/dashboard',
        component: () => (
          <Redirect to="/administrator/transactions/faas-land-plant-trees" />
        )
      },

      {
        exact: true,
        path: '/administrator/users',
        component: lazy(() => import('src/views/reports/DashboardAdmin/Users'))
      },

      {
        exact: true,
        path: '/administrator/referentials/property-type',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/referentials/property_types')
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/classification-group',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/classifications/groups'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/property-classification',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/property_classification'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/property-level-of-classification',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/classifications/levels'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/property-remarks',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/classifications/remarks'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/units',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/referentials/units')
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/planttrees',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/referentials/planttrees')
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-kinds',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/referentials/building/kinds')
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-type-of-structure',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/building/type_of_structure'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-classification',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/building/classification'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-structure-class',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/building/structure-and-class'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-components',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/building/components'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-materials',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/building/materials'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/building-extra-items',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/building/extra-items'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/adjustment-type',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/adjustment/type'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/adjustment-factor',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/adjustment/factor'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/assessment-transaction',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/assessment_transaction'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/machine-group',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/referentials/machine_group')
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/province',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/locations/province'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/municipality',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/locations/municipality'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/barangay',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/locations/barangay'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/barangay-roads',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/referentials/barangay-roads')
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/owner-information',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/owner_information'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/administrator-setup',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/administrator-setup'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/referentials/general-revision-setup',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/referentials/general-revision-setup'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/assessments',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/assessments-levels'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/schedule-base-units',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/schedule-of-base-unit-value-entry'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/schedule-bldg-cost',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/schedule-of-building-cost'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/depreciation-building',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/depreciation-building'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/depreciation-machine',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/depreciation-machine'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/faas-land-plant-trees',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-land-plants-trees/list'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/faas-land-plant-trees/create',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-land-plants-trees/create/index-old'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-land-plant-trees/create/:transStamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-land-plants-trees/create'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/:faasType/prop-info',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/incomplete-faas/property-information'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/:faasType/prop-info/new',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/incomplete-faas/New'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/:faasType/prop-info/update/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/incomplete-faas/Update'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-land-plant-trees/details/:trans_stamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-land-plants-trees/details'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-land-plant-trees/update/:trans_stamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-land-plants-trees/update'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/faas-building',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-building/list'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/faas-building/create',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-building/create'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-building/create/:transStamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-building/create'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-building/update/:trans_stamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-building/update'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/faas-machine',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-machine/list'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/transactions/faas-machine/create',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-machine/create'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-machine/create/:transStamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-machine/create'
          )
        )
      },

      {
        exact: true,
        path:
          '/administrator/transactions/faas-machine/update/:trans_stamp/:propInfoId',
        component: lazy(() =>
          import(
            'src/views/reports/DashboardAdmin/transactions/faas-machine/update'
          )
        )
      },

      {
        exact: true,
        path: '/administrator/reports/pjat',
        component: lazy(() => import('src/views/reports/DashboardAdmin/pjat'))
      },

      {
        exact: true,
        path: '/administrator/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path: '/administrator/reports/pjca',
        component: lazy(() => import('src/views/reports/DashboardAdmin/pjca'))
      },

      {
        exact: true,
        path: '/administrator/reports/assessment-roll',
        component: lazy(() =>
          import('src/views/reports/DashboardAdmin/assessment-roll')
        )
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        exact: true,
        path: '/home/terms-and-conditions',
        component: lazy(() => import('src/views/pages/TermsAndConditions'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  //** ebps routes **//

  {
    path: '/ebps',
    // layout: '',
    routes: [
      {
        exact: true,
        path: '/registration'
        // component: lazy(() => import('src/ebps/users/Registration'))
      },
      {
        exact: true,
        path: '/ebps/login',
        // layout: MainLayout,
        component: lazy(() => import('src/views/auth/LoginView'))
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
