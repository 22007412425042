// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { PieChart as PieChartIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  const key = item.title + depth;
  acc.push(
    <NavItem
      depth={depth}
      href={item.href}
      icon={item.icon}
      key={key}
      info={item.info}
      title={item.title}
      enabled={item.enabled}
      isCollapsible={item?.isCollapsible}
      collapseItems={item?.collapseItems ?? []}
    />
  );

  return acc;
}

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const navConfig = [
    {
      subheader: '',
      owner: user.roles[0].name,
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/app/reports/dashboard',
          enabled: true
        }
      ]
    },
    //administrator sub headers
    {
      subheader: 'Reports',
      owner: 'admin',
      items: [
        {
          title: 'Users',
          icon: ListAltIcon,
          href: '/administrator/users',
          enabled: true
        },

        {
          title: 'Journal of Assessment Transactions (PJAT)',
          icon: ListAltIcon,
          href: '/administrator/reports/pjat',
          enabled: true
        },

        {
          title: 'Journal of Assessment Canceled (PJCA)',
          icon: ListAltIcon,
          href: '/administrator/reports/pjca',
          enabled: true
        },

        {
          title: 'Assessment Roll',
          icon: ListAltIcon,
          href: '/administrator/reports/assessment-roll',
          enabled: true
        }
      ]
    },

    {
      subheader: 'Referentials',
      owner: 'admin',
      items: [
        {
          title: 'Property',
          icon: ListAltIcon,
          href: '',
          enabled: true,
          isCollapsible: true,
          collapseItems: [
            {
              title: 'Property Type',
              icon: ListAltIcon,
              href: '/administrator/referentials/property-type',
              enabled: true
            },

            {
              title: 'Classification Group',
              icon: ListAltIcon,
              href: '/administrator/referentials/classification-group',
              enabled: true
            },

            {
              title: 'Property Classification',
              icon: ListAltIcon,
              href: '/administrator/referentials/property-classification',
              enabled: true
            },

            {
              title: 'Property  Level Of Classification',
              icon: ListAltIcon,
              href:
                '/administrator/referentials/property-level-of-classification',
              enabled: true
            },

            {
              title: 'Property Remarks',
              icon: ListAltIcon,
              href: '/administrator/referentials/property-remarks',
              enabled: true
            },
            {
              title: 'Units',
              icon: ListAltIcon,
              href: '/administrator/referentials/units',
              enabled: true
            },
            {
              title: 'Plant & Trees',
              icon: ListAltIcon,
              href: '/administrator/referentials/planttrees',
              enabled: true
            }
          ]
        },

        {
          title: 'Building',
          icon: ListAltIcon,
          href: '',
          enabled: true,
          isCollapsible: true,
          collapseItems: [
            // building
            {
              title: 'Building Kinds',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-kinds',
              enabled: true
            },

            {
              title: 'Building Type of Structure',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-type-of-structure',
              enabled: true
            },

            {
              title: 'Building Classification',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-classification',
              enabled: true
            },

            {
              title: 'Building Structure and Class',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-structure-class',
              enabled: true
            },

            {
              title: 'Building Components',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-components',
              enabled: true
            },

            {
              title: 'Building Materials',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-materials',
              enabled: true
            },

            {
              title: 'Building Extra Items',
              icon: ListAltIcon,
              href: '/administrator/referentials/building-extra-items',
              enabled: true
            }
          ]
        },

        {
          title: 'Adjustment',
          icon: ListAltIcon,
          href: '',
          enabled: true,
          isCollapsible: true,
          collapseItems: [
            // adjustments
            {
              title: 'Adjustment Type',
              icon: ListAltIcon,
              href: '/administrator/referentials/adjustment-type',
              enabled: true
            },

            {
              title: 'Adjustment Factor',
              icon: ListAltIcon,
              href: '/administrator/referentials/adjustment-factor',
              enabled: true
            },

            {
              title: 'Assessment Transaction',
              icon: ListAltIcon,
              href: '/administrator/referentials/assessment-transaction',
              enabled: true
            },

            {
              title: 'Machine Group',
              icon: ListAltIcon,
              href: '/administrator/referentials/machine-group',
              enabled: true
            }

            // adjustments end
          ]
        },

        // locations
        {
          title: 'Location',
          icon: ListAltIcon,
          href: '',
          enabled: true,
          isCollapsible: true,
          collapseItems: [
            {
              title: 'Province',
              icon: ListAltIcon,
              href: '/administrator/referentials/province',
              enabled: true
            },

            {
              title: 'Municipality',
              icon: ListAltIcon,
              href: '/administrator/referentials/municipality',
              enabled: true
            },

            {
              title: 'Barangay',
              icon: ListAltIcon,
              href: '/administrator/referentials/barangay',
              enabled: true
            },

            {
              title: 'Barangay Roads',
              icon: ListAltIcon,
              href: '/administrator/referentials/barangay-roads',
              enabled: true
            }
          ]
        },

        {
          title: 'Owner Information',
          icon: ListAltIcon,
          href: '/administrator/referentials/owner-information',
          enabled: true
        },
        // loocations end

        //  extra
        {
          title: 'Administrator Setup',
          icon: ListAltIcon,
          href: '/administrator/referentials/administrator-setup',
          enabled: true
        },

        {
          title: 'General Revision Setup',
          icon: ListAltIcon,
          href: '/administrator/referentials/general-revision-setup',
          enabled: true
        }

        //  extra end
      ]
    },

    {
      subheader: 'Transactions',
      owner: 'admin',
      items: [
        {
          title: 'Assessments & Schedule',
          icon: ListAltIcon,
          href: '',
          enabled: true,
          isCollapsible: true,
          collapseItems: [
            {
              title: 'Assessments',
              icon: ListAltIcon,
              href: '/administrator/transactions/assessments',
              enabled: true
            },
            {
              title: 'Schedule of Base Units',
              icon: ListAltIcon,
              href: '/administrator/transactions/schedule-base-units',
              enabled: true
            },
            {
              title: 'Schedule of Building Cost',
              icon: ListAltIcon,
              href: '/administrator/transactions/schedule-bldg-cost',
              enabled: true
            },

            {
              title: 'Schedule of Depreciation For Building',
              icon: ListAltIcon,
              href: '/administrator/transactions/depreciation-building',
              enabled: true
            },

            {
              title: 'Schedule of Depreciation For Machine',
              icon: ListAltIcon,
              href: '/administrator/transactions/depreciation-machine',
              enabled: true
            },

            {
              title: 'FAAS - Land, Plant And Trees',
              icon: ListAltIcon,
              href: '/administrator/transactions/faas-land-plant-trees',
              enabled: true
            },

            {
              title: 'FAAS - Building',
              icon: ListAltIcon,
              href: '/administrator/transactions/faas-building',
              enabled: true
            },

            {
              title: 'FAAS - Machine',
              icon: ListAltIcon,
              href: '/administrator/transactions/faas-machine',
              enabled: true
            }
          ]
        }
      ]
    },

    {
      // users subheader
      subheader: 'Reports',
      owner: 'user',
      items: [
        {
          title: 'Documents',
          icon: ListAltIcon,
          href: '/app/documents',
          enabled: true
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              style={{
                display:
                  config.owner === user.roles[0].name
                    ? 'block'
                    : config.owner === 'barangay'
                    ? 'd-block'
                    : 'none'
              }}
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
